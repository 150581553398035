import * as React from "react";
import { PriceGroupCode } from "@testout/testout-commerce/models/commerce/PriceGroupCode"
import { Cart } from "@testout/testout-commerce/models/commerce/Cart";

 interface ISession {
    priceGroupCode?: PriceGroupCode;
    cart?: Cart;
}

const KEY_PGC = "cartsession_pricegroupcode";
const KEY_CART = "cartsession_cart";

interface INotifySessionChanged {
    (session: ISession | undefined): void;
}

const SessionContext = React.createContext(undefined as ISession | undefined);

let currentSession = {} as ISession;

let _notifySessionChanged : INotifySessionChanged | undefined = undefined;

function doNotify() {
    if (_notifySessionChanged)
        _notifySessionChanged(currentSession);
}

function attachNotifySessionChanged(callback: INotifySessionChanged) {
    _notifySessionChanged = callback;
    doNotify();
}
function setPriceGroupCode(priceGroupCode: PriceGroupCode | undefined) {
    currentSession = Object.assign({}, currentSession);
    if (priceGroupCode) {
        let s = JSON.stringify(priceGroupCode);
        window.sessionStorage.setItem(KEY_PGC, s);
        currentSession.priceGroupCode = new PriceGroupCode(JSON.parse(s));
    }
    else {
        currentSession.priceGroupCode = undefined;
        window.sessionStorage.removeItem(KEY_PGC);
    }

    doNotify();
}

function setCart(cart: Cart | undefined): Cart | undefined {
    currentSession = Object.assign({}, currentSession);
    if (cart) {
        let s = JSON.stringify(cart);
        window.sessionStorage.setItem(KEY_CART, s);
        currentSession.cart = new Cart(JSON.parse(s));
        if (currentSession.priceGroupCode)
            currentSession.cart.pricing = currentSession.priceGroupCode;
    }
    else{
        currentSession.cart = undefined;
        window.sessionStorage.removeItem(KEY_CART);
    }

    doNotify();
    return currentSession.cart;
}

function clearSession() {
    currentSession = {};
    window.sessionStorage.removeItem(KEY_PGC);
    window.sessionStorage.removeItem(KEY_CART);
    doNotify();
}

function loadSession() {
    let pgc = window.sessionStorage.getItem(KEY_PGC);
    currentSession = {} as ISession;
    if (pgc) {
        currentSession.priceGroupCode = new PriceGroupCode(JSON.parse(pgc));
        let cart = window.sessionStorage.getItem(KEY_CART);
        if (cart) {
            currentSession.cart = new Cart(JSON.parse(cart));
            currentSession.cart.pricing = currentSession.priceGroupCode;
        }
    }

    doNotify();
}
loadSession();

export { SessionContext, attachNotifySessionChanged, setPriceGroupCode, setCart, clearSession, currentSession };
export type { ISession, INotifySessionChanged };