import * as React from 'react';
import './InstitutionLookupCtl.scss';

interface IInstitutionLookupProps {
    value: string;
    items: string[];
    onChange?: (value: string) => void;
    inputRef?: React.RefObject<HTMLInputElement>;
}

interface IInstitutionLookupState {
    isOpen: boolean;
    focusIndex: number;
}

export default class Header extends React.PureComponent<IInstitutionLookupProps, IInstitutionLookupState> {
    constructor(props: IInstitutionLookupProps) {
        super(props);
        this.state = { isOpen: false, focusIndex: -1 };
        this._valueRef = this.props.inputRef ?? React.createRef<HTMLInputElement>();
    }

    _controlRef = React.createRef<HTMLDivElement>();
    _valueRef: React.RefObject<HTMLInputElement>;
    _scrollerRef = React.createRef<HTMLDivElement>();
    _itemRef = React.createRef<HTMLLIElement>();

    open = (value: string) => {
        if (this.props.onChange) this.props.onChange(value);
        this.setState({ isOpen: true, focusIndex: -1 });
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.open(e.target.value);
    }

    onClick = () => {
        if (!this.state.isOpen) {
            const inpValue = this._valueRef.current as HTMLInputElement;
            this.open(inpValue.value);
        }
    }

    onItemClick = (i: number) => {
        if (i >= 0 && i < this.props.items.length)
            if (this.props.onChange) this.props.onChange(this.props.items[i]);
        this.setState({ isOpen: false });
    }

    onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            const inpValue = this._valueRef.current as HTMLInputElement;
            if (this.state.isOpen)
                this.onItemClick(this.state.focusIndex);
            else
                this.open(inpValue.value);
            e.preventDefault();
        } else if (e.key === "ArrowDown") {
            if (this.state.focusIndex < this.props.items.length - 1)
                this.setState({ isOpen: true, focusIndex: this.state.focusIndex + 1 });
            e.preventDefault();
        } else if (e.key === "ArrowUp") {
            if (this.state.focusIndex > 0)
                this.setState({ isOpen: true, focusIndex: this.state.focusIndex - 1 });
            e.preventDefault();
        }
    }

    onDocumentFocusIn = (e: FocusEvent) => {
        const lookup = this._controlRef.current;
        if (lookup && !lookup.contains(e.target as Node))
            this.setState({ isOpen: false });
    }

    ensureItemVisible() {
        if (this.state.isOpen && this.state.focusIndex >= 0 && this._scrollerRef.current && this._itemRef.current) {
            const liItem = this._itemRef.current as HTMLLIElement;
            const divScroller = this._scrollerRef.current as HTMLDivElement;
            if (liItem.offsetTop < divScroller.scrollTop)
                divScroller.scrollTop = liItem.offsetTop;
            if (liItem.offsetTop + liItem.offsetHeight > divScroller.scrollTop + divScroller.clientHeight)
                divScroller.scrollTop = liItem.offsetTop + liItem.offsetHeight - divScroller.clientHeight;
        }
    }

    componentDidMount() {
        document.addEventListener("focusin", this.onDocumentFocusIn);
        this.ensureItemVisible();
    }

    componentDidUpdate(props: IInstitutionLookupProps) {
        this.ensureItemVisible();
    }

    componentWillUnmount() {
        document.removeEventListener("focusin", this.onDocumentFocusIn);
    }

    render() {
        return <div className="InstitutionLookupCtl" ref={this._controlRef}>
                   <input type="text" ref={this._valueRef} placeholder="Type to search" value={this.props.value} onKeyDown={this.onKeyDown} onChange={this.onChange} onClick={this.onClick} />
                   {this.state.isOpen && this.props.items.length > 0 &&
                       <div ref={this._scrollerRef}>
                           <ul>
                               {this.props.items.map((item, i) =>
                                   <li key={item} ref={i === this.state.focusIndex ? this._itemRef : undefined}
                                       className={i === this.state.focusIndex ? "focus" : undefined}
                                       onClick={() => this.onItemClick(i)}>{item}</li>)}
                           </ul>
                       </div>
                   }
               </div>;
    }
}