import * as React from 'react';
import './App.scss';
import './CheckoutPage.scss';
import TermsCtl from "./TermsCtl";

interface ITermsPageProps {

}

interface ITermsPageState {

}

export default class Footer extends React.PureComponent<ITermsPageProps, ITermsPageState> {
    constructor(props: ITermsPageProps) {
        super(props);
    }

    render() {
        return <div className="CheckoutPage-terms">
            <TermsCtl termLength={18} />
            </div>;
    }
}