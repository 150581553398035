import * as React from 'react';
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {currentSession, ISession, SessionContext, setCart} from './SessionContext';
import './App.scss';
import './CheckoutPage.scss';
import ico_close from "./Images/ico_close.png";
import logo_payment_cardconnect from "./Images/logo_payment_cardconnect.png";
import logo_payment_paypal from "./Images/logo_payment_paypal.png";
import CheckoutCustomer from "./CheckoutCustomer";
import {StringDictionary} from "@testout/testout-commerce/core/StringDictionary";
import { Cart, ICart } from "@testout/testout-commerce/models/commerce/Cart";
import { Customer } from '@testout/testout-commerce/models/commerce/Customer';
import Strings from "@testout/testout-commerce/core/Strings";
import MaintenanceMessage from './MaintenanceMessage';
import TermsCtl from './TermsCtl'
import InstitutionLookupCtl from './InstitutionLookupCtl'

interface ICheckoutPageProps extends RouteComponentProps {

}

interface ICheckoutPageState {
    initialized: boolean;
    acceptTerms: boolean;
    showTerms: boolean;
    institutions: StringDictionary;
    institution: string;
    suggestedInstitutions: string[];
    availableDeliveryProviders: IDeliveryProviderService[];
    isStudent: boolean;
    isRetail: boolean;
    hasLicenseInCart: boolean;
    shippingCustomer: Customer;
    billingCustomer: Customer;
    billingSame: boolean;
    paymentType: "credit" | "paypal";

    institutionInvalid?: boolean;
    termsInvalid?: boolean;
    certPrinterInvalid?: boolean;
    validationMessage?: string;

    deliveryTip: string;
}

interface IDeliveryProviderService {
    name: string;
    freightAmount: number;
    handlingAmount: number;
    currencyCode: string;
    isSelected: boolean;
}

interface IPreppedCart {
    availableDeliveryProviders: IDeliveryProviderService[];
    cart: ICart;
}

enum RedirectType {
    RedirectUrl = 0,
    FormPost = 1
}

interface IPaymentRedirect {
    updatedCart: ICart;
    redirectUrl: string;
    redirectType: RedirectType;
    formValues: {[name: string]: string};
}

class CheckoutPage extends React.PureComponent<ICheckoutPageProps, ICheckoutPageState> {
    private _shippingCustomerCtl: CheckoutCustomer | null = null;
    private _billingCustomerCtl: CheckoutCustomer | null = null;
    constructor(props: ICheckoutPageProps) {
        super(props);
        this.state = {
            initialized: false,
            isStudent: true,
            isRetail: false,
            hasLicenseInCart: false,
            acceptTerms: false,
            showTerms: false,
            institutions: new StringDictionary(),
            institution: "",
            suggestedInstitutions: [],
            availableDeliveryProviders: [],
            shippingCustomer: new Customer(),
            billingCustomer: new Customer(),
            billingSame: true,
            paymentType: "credit",
            deliveryTip: "the address you enter"
        };
    }

    _institutionLookupRef = React.createRef<HTMLInputElement>();

    async componentDidMount() {
        window.scrollTo(0, 0);
        let session = currentSession;
        if (session !== null) {
            let institutions = await this.getInstitutions(session);
            let hasLicenseInCart = false;
            if (session.cart?.items)
                for (var item of session.cart.items)
                    if (item.fulfillmentProvider === "LabSimServer") {
                        hasLicenseInCart = true;
                        break;
                    }
            this.setState({
                initialized: true,
                acceptTerms: session.cart?.licenseTermsAccepted ?? false,
                isStudent: session.cart?.shippingCustomer?.isStudent ?? true,
                hasLicenseInCart: hasLicenseInCart,
                shippingCustomer: session.cart?.shippingCustomer ?? new Customer(),
                billingCustomer: session.cart?.billingCustomer ?? new Customer(),
                paymentType: session.cart?.paymentProviderName?.key === "PayPal" ? "paypal" : "credit",
                isRetail: session.cart?.pricing.isRetail ?? false,
                institutions: institutions,
                institution: session.cart?.institutionCustomer?.name ?? (institutions.length > 0 ? institutions[0].Key.toString() : "")
            }, async () => {
                this.prepCart();
                if (this.state.institutions.length === 0) {
                    const inpInstitution = this._institutionLookupRef.current as HTMLInputElement;
                    inpInstitution?.focus();
                }
            });
        }
    }

    async getInstitutions(session: ISession) {
        const cart = session.cart as Cart;
        if (cart?.pricing.priceList && cart?.pricing.priceList.isUserRequired) {
            const sd = new StringDictionary();
            if (cart.user && cart.user.institution) {
                sd.set(cart.user.institution.customerNumber, cart.user.institution.name);
                cart.institutionCustomer = cart.user.institution;
                this.doSetCart(cart);
            }
            return sd;
        }
        if (!cart?.pricing.isRetail && (cart?.pricing?.customerCount ?? 0) > 0 && (cart?.pricing?.customerCount ?? 0) <= 50) {
            const response = await fetch(`controller/cart/pricegroupcustomerdictionary/${session?.priceGroupCode?.priceGroupId}`);
            return StringDictionary.fromSimpleObject(await response.json());
        }
        return new StringDictionary();
    }


    private _lastInstitutionSearch : string | undefined = undefined;
    async populateSuggestedInstitutions() {
        let search = this.state.institution;
        if (this._lastInstitutionSearch !== search) {
            if (this.state.institution.length < 3)
                this.setState({ suggestedInstitutions: [] });
            else {
                let session = currentSession;
                const response = await fetch(`controller/cart/pricegroupcustomerdictionary/${session?.priceGroupCode?.priceGroupId}?searchText=${encodeURIComponent(this.state.institution)}`);
                let items: string[] = StringDictionary.fromSimpleObject(await response.json()).map(kv => kv.Value);
                this.setState({ suggestedInstitutions: items });
            }
        }
    }

    private _debounceTimer: number | undefined = undefined;
    applyTypedInstitution(typedVal: string) {
        this.setState({ institution: typedVal }, () => {
            if (this._debounceTimer) 
                window.clearTimeout(this._debounceTimer);
            this._debounceTimer = window.setTimeout(() => {
                this._debounceTimer = undefined;
                this.populateSuggestedInstitutions()
            }, 500);
        });
    }

    focusInvalid() {
        let elems = document.getElementsByClassName("invalid");
        if (elems !== null && elems.length > 0 && elems[0] instanceof HTMLElement)
            (elems[0] as HTMLElement).focus();
    }

    doSetCart(updatedCart: Cart) : Cart {
        let cart = currentSession.cart as Cart;
        if (!Strings.isNullOrEmpty(cart.orderNumber) && Strings.isNullOrEmpty(updatedCart.orderNumber)) {
            //We've already been assigned an order number, but a race condition has caused us to lose it
            //We'll put it back in place here.
            updatedCart.orderNumber = cart.orderNumber;
            return cart;
        }
        return setCart(updatedCart) as Cart;
    }

    private _lastPreppedParms = "";
    async applyCart(updateInvalid: boolean): Promise<boolean> {
        if (!this.state.initialized)
            return false;

        let cart = currentSession.cart as Cart;

        //If the user tried to load this page a session/cart they will be redirected back to PriceCodePage
        //but they will get one render before that happens, and that render won't render the shipping control
        //so we'll just skip applying when we don't have the control
        if (!this._shippingCustomerCtl)
            return false;

        let cShip = this._shippingCustomerCtl?.getUpdatedCustomer(updateInvalid);
        let cBill: {customer: Customer, hasInvalid: boolean} | null = null;
        if (this._billingCustomerCtl)
            cBill = this._billingCustomerCtl?.getUpdatedCustomer(updateInvalid);

        if (cShip) {
            cart.shippingCustomer = cShip.customer;
            cart.shippingCustomer.isStudent = this.state.isStudent;
        }
        cart.billingCustomer = cBill ? cBill.customer : undefined;
        if (cart.billingCustomer)
            cart.billingCustomer.isStudent = this.state.isStudent;
        cart.billingSameAsShipping = this.state.billingSame;
        cart.paymentProviderName = {key: this.state.paymentType === "credit" ? "CardConnect" : "PayPal", value: "Cash"};
        cart.licenseTermsAccepted = this.state.acceptTerms;

        let institutionSearch =
            cart.user && cart.user.institution
                ? "" //We already have an institution and don't need to search for one
                : `${encodeURIComponent(this.state.institution)}|${this.state.institutions == null || this.state.institutions.length === 0}`;
        let prepParms = `${cart.cartId}|${JSON.stringify(cart.shippingCustomer.address.postal)}|${institutionSearch}`;
        if (prepParms !== this._lastPreppedParms) {
            this._lastPreppedParms = prepParms;
            const response = await fetch(`controller/cart/prepcart/${currentSession.priceGroupCode?.priceGroupId}/${currentSession.priceGroupCode?.priceCode}?institutionSearch=${institutionSearch}`, {
                method: "POST",
                body: JSON.stringify(cart),
                headers: { 'Content-Type': 'application/json' }
            });
            let prepped = await response.json() as IPreppedCart;
            this.setState({ availableDeliveryProviders: prepped.availableDeliveryProviders }, () => {
                let updatedCart = new Cart(prepped.cart);
                if (prepParms === this._lastPreppedParms)
                    this.doSetCart(updatedCart);
            });
        }
        else this.doSetCart(cart);

        let invalid = cShip?.hasInvalid ?? false;
        if (this._billingCustomerCtl)
            invalid = invalid || (cBill?.hasInvalid ?? false);
        return invalid;
    }

    async prepCart() {
        if (!this.state.initialized)
            return;

        await this.applyCart(false);
    }

    private _doingCheckout: boolean = false;
    async attemptCheckout() {
        if (this._doingCheckout)
            return;

        let invalid = await this.applyCart(true);

        let cart = currentSession.cart as Cart;
        let institutionInvalid = !cart.fulfillmentProvider.containsKey("CertificatePrinter") && (cart.institutionCustomer == null || Strings.isNullOrEmpty(cart.institutionCustomer.name));
        let termsInvalid = this.state.hasLicenseInCart && !this.state.acceptTerms;

        let validationMessage = "";
        if (this.state.isRetail === false && institutionInvalid) {
            if (Strings.isNullOrEmpty(this.state.institution))
                validationMessage += "Enter the institution you attend. ";
            else validationMessage = "The institution you entered could not be found. Please verify the correct spelling has been entered. " + validationMessage;
        }
        if (termsInvalid)
            validationMessage += "Please review the TestOut License Terms and check the box to signify your agreement. ";

        let certPrinterInvalid = cart.fulfillmentProvider.containsKey("CertificatePrinter") && cart.shippingCustomer.address.postal.countryCode !== "USA" && cart.shippingCustomer.address.postal.countryCode !== "CAN";

        invalid = invalid || institutionInvalid || termsInvalid || certPrinterInvalid;
        this.setState({
            validationMessage: validationMessage, institutionInvalid: institutionInvalid, termsInvalid: termsInvalid, certPrinterInvalid: certPrinterInvalid
        }, async () => {
            if (invalid)
                this.focusInvalid();
            else {
                if (!this._doingCheckout) {
                    this._doingCheckout = true;
                    try {
                        cart.paymentProviderErrorMessage = "";
                        let response = await fetch(`controller/cart/preparepaymentredirect`, {
                            method: "POST",
                            body: JSON.stringify(currentSession.cart),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        if (response.status === 200) {
                            let pr = await response.json() as IPaymentRedirect
                            let cart = new Cart(pr.updatedCart);
                            this.doSetCart(cart);
                            //We want to let the session have a chance to save before we redirect
                            setTimeout(() => {
                                this._doingCheckout = false;
                                switch (pr.redirectType) {
                                    case RedirectType.RedirectUrl:
                                        window.location.assign(pr.redirectUrl);
                                        break;
                                    case RedirectType.FormPost:
                                        let form = document.createElement('form');
                                        document.body.appendChild(form);
                                        form.method = 'POST';
                                        form.action = pr.redirectUrl;
                                        for (var name in pr.formValues) {
                                            if (pr.formValues.hasOwnProperty(name)) {
                                                var input = document.createElement('input');
                                                input.type = 'hidden';
                                                input.name = name;
                                                input.value = pr.formValues[name];
                                                form.appendChild(input);
                                            }
                                        }
                                        form.submit();
                                        break;
                                }
                            }, 0);
                        }
                        else {
                            this._doingCheckout = false;
                            this.setState({ validationMessage: "An error occurred, please try again." });
                        }
                    }
                    catch {
                        this._doingCheckout = false;
                    }
                }
            }
        });
    }

    updateDeliveryTip() {
        let tip = "the address you enter";
        if (this._shippingCustomerCtl) {
            let c = this._shippingCustomerCtl.getUpdatedCustomer(false);
            let add = c.customer.address;
            let s = (`${add.contactPerson.firstNames} ${add.contactPerson.lastName}`).trim();
            if (!Strings.isNullOrEmpty(add.email))
                s += ` <${add.email}>`;
            if (!Strings.isNullOrEmpty(s))
            tip = s;
        }
        this.setState({deliveryTip: tip});
    }

    getSelectedInstitutionValue() {
        for (var kv of this.state.institutions) {
            if (kv.Key === this.state.institution || kv.Value === this.state.institution) {
                return kv.Key;
            }
        }
        return "";
    }

    render() {
        return <SessionContext.Consumer>
                   {
                session => {
                    if (session == null || session.cart == null || session.cart.items.length < 1) {
                        //You shouldn't be here, so we'll try to get you back where you belong
                        setTimeout(() => {
                            this.props.history.replace("/")
                        }, 1);
                        return <></>;
                    }

                    return <div className={`App-body ${this.state.initialized ? "" : "InitializingBodyPage"}`} onKeyPress={(e) => {
                        if (e.code === "Enter" || e.code === "NumpadEnter")
                            this.attemptCheckout();
                        }}>
                               <MaintenanceMessage />
                               {!this.state.initialized && <div><div className="spinner"></div></div>}
                               {this.state.initialized && <div className="CheckoutPage">
                                                              {!this.state.showTerms && <>
                                <h1>Checkout</h1>
                                <ol className="CheckoutPage-progress">
                                    <li className="CheckoutPage-progress-current"><span>Information</span></li>
                                    <li><span>Payment</span></li>
                                    <li><span>Receipt</span></li>
                                </ol>
                                <div className="CheckoutPage-columns">
                                    <div>
                                        {!Strings.isNullOrEmpty(session?.cart.paymentProviderErrorMessage) && <div className="validationMessage">{session?.cart.paymentProviderErrorMessage}</div>}
                                        {(this.state.isRetail === false || this.state.hasLicenseInCart === true) &&
                                           <div>
                                            {this.state.isRetail === false && <h3>Institution & Terms</h3>}
                                            {!Strings.isNullOrEmpty(this.state.validationMessage) && <div className="validationMessage">{this.state.validationMessage}</div>}
                                            <div className="CheckoutPage-tip">
                                                {this.state.isRetail === false && <p className="tick">Select the institution you attend. We will associate any
                                                    LabSim products in your order with the selected institution.</p>}
                                                {this.state.hasLicenseInCart === true && <p>Use of LabSim products is subject to the TestOut license terms.</p>}
                                            </div>
                                           {this.state.isRetail === false && <label htmlFor="institution">Institution</label>}
                                           {this.state.isRetail === false && this.state.institutions.length > 0 &&
                                                <select id="institution" disabled={this.state.institutions.length === 1} value={this.getSelectedInstitutionValue()} onChange={(e) => { if (!Strings.isNullOrEmpty(e.target.value)) this.setState({ institution: e.target.value })} }>
                                                    {this.state.institutions.map(kv => {
                                                        return <option key={kv.Key} value={kv.Key}>{kv.Value}</option>;
                                                    })}
                                                </select>
                                            }
                                            {this.state.isRetail === false && this.state.institutions.length === 0 &&
                                                <InstitutionLookupCtl inputRef={this._institutionLookupRef} value={this.state.institution} onChange={(value: string) => this.applyTypedInstitution(value)} items={this.state.suggestedInstitutions} />
                                            }
                                           {this.state.isRetail === false && <span className="checkbox">
                                                <input type="checkbox" id="isStudent" checked={this.state.isStudent}
                                                    onChange={(e) => this.setState({ isStudent: e.target.checked })} />
                                                <label htmlFor="isStudent">I am a student at this institution</label>
                                            </span> }
                                            {this.state.hasLicenseInCart === true && <span className="checkbox">
                                                <input type="checkbox" id="acceptTerms" checked={this.state.acceptTerms}
                                                    onChange={(e) => this.setState({ acceptTerms: e.target.checked })} />
                                                <label htmlFor="acceptTerms" className={this.state.termsInvalid ? "invalid" : ""}>I accept the&nbsp;
                                                    <a className="chromeless"
                                                        onClick={() => this.setState({ showTerms: true })}>TestOut License Terms</a>
                                                </label>
                                            </span>}
                                          </div>
                                        }
                                        <div>
                                            <h3>Delivery</h3>
                                            <div className="CheckoutPage-tip">
                                                <p className="tick">This information is used to deliver your order, calculate
                                                    applicable taxes, and to resolve any delivery issues.</p>
                                                {session.cart?.fulfillmentProvider.containsKey("LabSimServer") && <p>The digital goods in your order will be emailed to {this.state.deliveryTip}.
                                                    While most emails are sent within a few minutes of submitting your order,
                                                    some may take longer. Please add custservice@testout.com to your&nbsp;
                                                    <a target="_blank" href="https://www.testout.com/company/whitelist-email-addresses" className="chromeless">safe-senders list</a> or check your junk email folder to ensure you receive the
                                                    email.</p>
                                                }
                                                {session.cart?.fulfillmentProvider.containsKey("CertificatePrinter") && <>
                                                    {this.state.availableDeliveryProviders.map(dp => {
                                                        if (dp.name === "Certificate Printer") {
                                                            return <>
                                                                <p>The certificates in your order will be sent to {this.state.deliveryTip}. <span className="bold">We can only deliver certificates to postal addresses in the United States and Canada.</span> Please allow 4 to 6 weeks for processing and delivery.</p>
                                                                {(this.state.shippingCustomer?.address?.postal?.countryCode === "USA" || this.state.shippingCustomer?.address?.postal?.countryCode === "CAN") && <>
                                                                    <p className="CheckoutPage-shippingTip">Shipping & Handling</p>
                                                                    <div className="CheckoutPage-shippingProvider">
                                                                        <span className="radio">
                                                                            <input type="radio" id="pService" defaultChecked={true} />
                                                                            <label htmlFor="pService">Printing Service</label>
                                                                        </span>
                                                                        <span>{((dp.freightAmount ?? 0) + (dp.handlingAmount ?? 0)).toLocaleString('en-US', {
                                                                            style: 'currency',
                                                                            currency: 'USD' //dp.currencyCode
                                                                        })}</span>
                                                                    </div>
                                                                </>}
                                                            </>
                                                        }
                                                    })}
                                                    {this.state.availableDeliveryProviders.length === 0 && <>
                                                        <p className="italic">We can only deliver certificates to postal addresses in the United States and Canada.</p>
                                                        {this.state.certPrinterInvalid && <div className="validationMessage">Delivery is not available to the entered address.</div>}
                                                    </>}
                                                </>}
                                            </div>
                                            <CheckoutCustomer ref={(ctl) => { this._shippingCustomerCtl = ctl; this.updateDeliveryTip() }} customer={this.state.shippingCustomer} locationChange={() => this.prepCart()} nameOrEmailChange={() => this.updateDeliveryTip()} autoCompleteSection="shipping" />
                                        </div>
                                        <div>
                                            <h3>Billing</h3>
                                            <div className="CheckoutPage-tip">
                                                <p className="tick">This information is used to deliver an invoice or receipt,
                                                    and to resolve any payment issues. Card payment is provided by
                                                    CardPointe.</p>
                                                <p>An order confirmation will be emailed to the address you enter.</p>
                                            </div>
                                            <span className="checkbox">
                                                <input type="checkbox" id="billingSame" checked={this.state.billingSame}
                                                    onChange={(e) => this.setState({ billingSame: e.target.checked })} />
                                                <label htmlFor="billingSame">Use the delivery details above</label>
                                            </span>
                                            {this.state.billingSame && <div className="form-spacer"></div>}
                                            {!this.state.billingSame && <>
                                                <CheckoutCustomer ref={(ctl) => this._billingCustomerCtl = ctl} customer={this.state.billingCustomer} autoCompleteSection="billing" />
                                            </>}
                                            <label>Payment Method</label>
                                            <span className="radio">
                                                <input type="radio" id="credit" checked={this.state.paymentType === "credit"}
                                                    onChange={(e) => this.setState({ paymentType: e.target.checked ? "credit" : "paypal" })} />
                                                <label htmlFor="credit"><img src={logo_payment_cardconnect} width="170px" height="24px" /></label>
                                            </span>
                                            <span className="radio">
                                                <input type="radio" id="paypal" checked={this.state.paymentType === "paypal"}
                                                    onChange={(e) => this.setState({ paymentType: e.target.checked ? "paypal" : "credit" })} />
                                                <label htmlFor="paypal"><img src={logo_payment_paypal} width="90px" height="22px" /></label>
                                            </span>
                                            {(session.cart.totalDiscountAmount > 0 || session.cart.totalTaxAmount > 0 || session.cart.freightAmount > 0) &&
                                                <div className="CheckoutPage-total first">
                                                    <span>{session.cart.totalItemAmount.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD' //session.cart.currencyCode
                                                    })}</span>
                                                    <span>Subtotal</span>
                                                </div>
                                            }
                                            {session.cart.totalDiscountAmount > 0 &&
                                                <div className="CheckoutPage-total">
                                                    <span>{session.cart.totalDiscountAmount.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD' //session.cart.currencyCode
                                                    })}</span>
                                                    <span>Discount</span>
                                                </div>
                                            }
                                            {session.cart.totalTaxAmount > 0 &&
                                                <div className="CheckoutPage-total">
                                                    <span>{session.cart.totalTaxAmount.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD' //session.cart.currencyCode
                                                    })}</span>
                                                    <span>Sales Tax</span>
                                                </div>
                                            }
                                            {session.cart.freightAmount > 0 &&
                                                <div className="CheckoutPage-total">
                                                    <span>{session.cart.freightAmount.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD' //session.cart.currencyCode
                                                    })}</span>
                                                    <span>Shipping & Handling</span>
                                                </div>
                                            }
                                            <div className={(session.cart.totalDiscountAmount > 0 || session.cart.totalTaxAmount > 0 || session.cart.freightAmount > 0) ? "CheckoutPage-total last" : "CheckoutPage-total first last"}>
                                                <span>{session.cart.totalAmount.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD' //session.cart.currencyCode
                                                })}</span>
                                                <span>Total</span>
                                            </div>
                                            <div className="CheckoutPage-links">
                                                <a onClick={() => this.attemptCheckout()}>Continue</a>
                                                <a className="chromeless" onClick={() => this.props.history.push("/Cart")}>Return to Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {/*This Empty div does need to be here to create the second column*/}
                                    </div>
                                </div>
                            </>}
                                                              {this.state.showTerms && <>
                                <div className="CheckoutPage-terms">
                                    <a className="chromeless" onClick={() => this.setState({ showTerms: false }) }><img
                                        src={ico_close} width="18px" height="18px" /></a>
                                    <TermsCtl termLength={18} />
                                    <div className="CheckoutPage-close"><a className="reverse"
                                        onClick={() => { window.scrollTo(0, 0); this.setState({ showTerms: false }) }}>Close</a></div>
                                </div>
                            </>}
                                                          </div>}
                           </div>
                }
            }
               </SessionContext.Consumer>;
    }
}

export default withRouter(CheckoutPage);