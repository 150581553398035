import * as React from 'react';
import {SessionContext, clearSession, ISession, setCart} from "./SessionContext";
import Strings from "@testout/testout-commerce/core/Strings";
import "./ProductsPage.scss";
import {Cart} from "@testout/testout-commerce/models/commerce/Cart";
import { Price } from '@testout/testout-commerce/models/commerce/Price';
import { StringDictionary } from '@testout/testout-commerce/core/StringDictionary';
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import MaintenanceMessage from './MaintenanceMessage';

interface IProductsPageProps extends RouteComponentProps {

}

interface IProductsPageState {

}

class ProductsPage extends React.PureComponent<IProductsPageProps, IProductsPageState> {
    constructor(props: IProductsPageProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    addToCart(session: ISession, price: Price) {
        if (price.product) {
            let c: Cart;
            if (session.cart)
                c = session.cart;
            else {
                c = new Cart();
                if (session.priceGroupCode)
                    c.pricing = session.priceGroupCode;
            }

            let properties = new StringDictionary();
            if (c.user)
                properties.set("User", `${c.user.customerUserId}^${c.user.contact.firstNames} ${c.user.contact.lastName}`);

            let desc = Strings.isNullOrEmpty(price.product.isbn) ? "" :`ISBN: ${price.product?.isbn}`;
            if (price.product.components.length > 0) {
                desc += "; Includes: ";
                let first = true;
                price.product.components.forEach(c => {
                    if (!first)
                        desc += ", ";
                    if (c.quantity > 1 || c.unitCode !== price.unitCode || c.product?.unitType !== price.product?.unitType) {
                        let s = "";
                        if (c.quantity > 1)
                            s += c.quantity;
                        if (c.unitCode !== price.unitCode || c.product?.unitType !== price.product?.unitType) {
                            if (s.length > 0)
                                s += " ";
                            s += `${c.unitCode} ${c.product?.unitType}`;
                        }
                        desc += `(${s})`;
                    }
                    desc += c.product?.getLocalizedTitle("en-US");
                    first = false;
                });
            }
            c.addItem(price.productNumber, price.unitCode, desc, 1, properties);

            setCart(c);
            this.props.history.push("/Cart");
        }
    }

    render() {
        return <SessionContext.Consumer>
                   {session =>
                <div className="App-body">
                    <MaintenanceMessage />
                    <div className="ProductsPage">
                        <h1>Price List</h1>
                        <p>The products and pricing available to you are listed below.</p>
                        {session?.priceGroupCode?.getEffectivePrices(session?.cart?.user).some(price => price.product?.isbn)
                            && <p className="red bold">Please verify the product name and ISBN number given by your instructor before adding to your cart.</p>}
                        <div className="ProductsPage-table">
                            <div className="ProductsPage-tableHeader">
                                <span>Item</span>
                                <span>Price</span>
                                <span></span>
                            </div>
                            {session?.priceGroupCode?.getEffectivePrices(session?.cart?.user).map((price) => {
                                return <div className="ProductsPage-tableRow" key={price.productNumber}>
                                    <div className="ProductsPage-productTitle">
                                        <img src={price.product?.thumbnailUrl} width={48} height={48} />
                                        <div>
                                            {price.product?.title}{price.product?.hasUserProperty && <span>&nbsp;{`(${session?.cart?.user?.contact.firstNames} ${session?.cart?.user?.contact.lastName})`}</span>}
                                            <div><span>{price.unitCode === "Each" ? "" : price.unitCode} {price.product?.unitType}</span>{!Strings.isNullOrEmpty(price.product?.isbn) && <span>ISBN: {price.product?.isbn}</span>}</div>
                                        </div>
                                        <div className="ProductsPage-smallScreenPrice">{price.unitPrice.toLocaleString('en-US', {style: 'currency', currency: price.currencyCode})}</div>
                                    </div>
                                    <span className="ProductsPage-largeScreenPrice">{price.unitPrice.toLocaleString('en-US', {style: 'currency', currency: price.currencyCode})}</span>
                                    <span><a onClick={() => this.addToCart(session, price)}>Add to Cart</a></span>
                                </div>
                            })}
                        </div>
                        <div className="ProductsPage-links">
                            {session?.cart && session?.cart.items.length > 0 && <a className="reverse" onClick={() => this.props.history.push("/Cart")}>View Cart</a>}
                            <a className="underlinedlink" onClick={() => clearSession()}>Start Over</a>
                        </div>
                    </div>
                </div>
            }
               </SessionContext.Consumer>;
    }
}

export default withRouter(ProductsPage);