import * as React from 'react';
import './PriceCodePage.scss';
import {IPriceGroupCode,PriceCodeStatus,PriceGroupCode} from "@testout/testout-commerce/models/commerce/PriceGroupCode";
import {PriceListStatus} from "@testout/testout-commerce/models/commerce/PriceList";
import {currentSession, setCart, setPriceGroupCode} from "./SessionContext";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import ico_educators from './Images/ico_educators_large.png';
import ico_professionals from './Images/ico_professionals_large.png';
import ico_students from './Images/ico_students_large.png';
import Strings from "@testout/testout-commerce/core/Strings";
import { Cart } from '@testout/testout-commerce/models/commerce/Cart';
import { CustomerUser, ICustomerUser } from '@testout/testout-commerce/models/commerce/CustomerUser';
import QueryString from "./QueryString";
import MaintenanceMessage from "./MaintenanceMessage";

interface IPriceCodePageProps extends RouteComponentProps {

}

interface IPriceCodePageState {
    initialized: boolean;
    priceCode: string;
    validationMessage?: string | JSX.Element;
    requireUser: boolean;
    login: string;
}

class PriceCodePage extends React.PureComponent<IPriceCodePageProps, IPriceCodePageState> {
    constructor(props: IPriceCodePageProps) {
        super(props);
        this.state = { initialized: false, priceCode: (QueryString.has("code") ? QueryString.get("code") : "") ?? "", requireUser: false, login: "" };
    }

    private _user: CustomerUser | undefined;
    async componentDidMount() {
        window.scrollTo(0,0);
        if (QueryString.has("user")) {
            try {
                const response = await fetch(`controller/cart/user/${encodeURIComponent(QueryString.get("user") as string)}`);
                if (response.status == 200) {
                    this._user = new CustomerUser(await response.json() as ICustomerUser);
                    this.setState({ login: this._user.login ?? "" });
                }
            }
            catch {}
        }
        if (this.state.priceCode)
            await this.loadPricing();
        this.setState({ initialized: true });
    }

    async loadPricing() {
        if (!Strings.isNullOrEmpty(this.state.priceCode)) {
            const response = await fetch(`controller/cart/pricegroupcode/${this.state.priceCode}`);
            if (response.status == 200) {
                let pgc = new PriceGroupCode(await response.json() as IPriceGroupCode);
                let isValid = pgc != null && pgc.status === PriceCodeStatus.Active &&
                    pgc.priceList != null && pgc.priceList.status === PriceListStatus.Active;
                if (isValid) {
                    setPriceGroupCode(pgc);
                    let c = new Cart();
                    c.pricing = pgc;
                    c.user = this._user;
                    setCart(c);
                    this.setState({ requireUser: pgc.priceList?.isUserRequired || false, validationMessage: undefined });
                    return;
                }
            }
            this.setState({ requireUser: false, validationMessage: "The price code you entered was not found. Please verify the correct code has been entered."});
        }
        else this.setState({ requireUser: false, validationMessage: "The price code must not be empty."});
    }

    getLabSimHref() {
        if (window.location.toString().indexOf("shoptest") > -1 || window.location.toString().indexOf("localhost") > -1)
            return "https://labsimapp-test.testout.com";
        return "https://labsimapp.testout.com";
    }

    loadUser() {
        if (!Strings.isNullOrEmpty(this.state.login)) {
            //The server seems to be slow on this method, so setting state back to initializing helps the user to see we are doing something
            this.setState({ initialized: false }, async () => {
                try {
                    const response = await fetch(`controller/cart/user/${encodeURIComponent(this.state.login)}`);
                    if (response.status == 200) {
                        let c = currentSession.cart as Cart;
                        c.user = new CustomerUser(await response.json() as ICustomerUser);
                        setCart(c);
                        this.setState({ initialized: true, validationMessage: undefined })
                        return;
                    }
                    else if (response.status == 401) {
                        this.setState({ initialized: true, validationMessage: <span>You must be signed in to LabSim to purchase items of this type. <a href={this.getLabSimHref()} target="_blank" className="underlinedlink">Click here</a> to sign in to LabSim, then return to this tab to complete your purchase.</span> });
                        return;
                    }
                }
                catch { };
                this.setState({ initialized: true, validationMessage: "The login you entered was not found. Please verify the correct login has been entered." });
            });
        }
        else this.setState({ validationMessage: "The login must not be empty." });
    }

    render() {
        return <div className={`App-body ${this.state.initialized ? "" : "InitializingBodyPage"}`}>
            <MaintenanceMessage />
            {!this.state.initialized && <div><div className="spinner"></div></div>}
            {this.state.initialized && <div className="PriceCodePage">
                <h1>Enter your Price Code</h1>
                <p>Enter the price code you received from your institution or instructor. The code determines what products and discount pricing are available to you. Please contact them if you don't have one.</p>
                <div className="App-form PriceCodePage-priceCode">
                    {this.state.validationMessage != null && this.state.validationMessage !== "" && <div className="validationMessage">{this.state.validationMessage}</div>}
                    <label>Price Code</label>
                    <input autoFocus={true} disabled={this.state.requireUser} value={this.state.priceCode} onChange={(e) => this.setState({ priceCode: e.target.value.toUpperCase() })} type="text"
                        onKeyDown={(e) => {
                            if (e.code === "Enter" || e.code === "NumpadEnter")
                                this.loadPricing();
                        }} autoCorrect="off" spellCheck="false" maxLength={100} />
                    {this.state.requireUser && <>
                        <label>LabSim Login</label>
                        <input autoFocus={true} value={this.state.login} onChange={(e) => this.setState({ login: e.target.value })} type="text"
                            onKeyDown={(e) => {
                                if (e.code === "Enter" || e.code === "NumpadEnter")
                                    this.loadUser();
                            }} autoCorrect="off" spellCheck="false" maxLength={255} />
                    </>}
                    <a onClick={() => this.state.requireUser ? this.loadUser() : this.loadPricing()}>Continue</a>
                </div>
                <div className="PriceCodePage-links">
                    <div>
                        <label>Students</label>
                        <div className="PriceCodePage-links-main">
                            <img src={ico_students} />
                            <p>Check your syllabus or contact your instructor for more information on purchasing.</p>
                        </div>
                        <a target="_blank" href="http://www.testout.com/resources/student-resources/student-discount" className="light">Student Discount</a>
                    </div>
                    <div>
                        <label>Educators</label>
                        <div className="PriceCodePage-links-main">
                            <img src={ico_educators} />
                            <p>We offer discounted pricing for academic institutions. Request pricing for more information.</p>
                        </div>
                        <a target="_blank" href="https://hs.testout.com/request-acad-pricing-info" className="light">Instructor Pricing</a>
                    </div>
                    <div>
                        <label>Professionals</label>
                        <div className="PriceCodePage-links-main">
                            <img src={ico_professionals} />
                            <p>Looking to advance your career by getting certified? We've got comprehensive training for professionals.</p>
                        </div>
                        <a target="_blank" href="http://www.testoutce.com/" className="light">Show Pricing</a>
                    </div>
                </div>
            </div>}
        </div>;
    }
}

export default withRouter(PriceCodePage);