import * as React from 'react';
import './App.scss';
import './CheckoutPage.scss';

interface ITermsCtlProps {
    termLength: number
}

interface ITermsCtlState {

}

export default class Footer extends React.PureComponent<ITermsCtlProps, ITermsCtlState> {
    constructor(props: ITermsCtlProps) {
        super(props);
        props = { termLength: 0 };
    }

    private _small = [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen",
        "Eighteen", "Nineteen"
    ];
    private _tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    private _hundred = "Hundred";
    private _scale = ["", "Thousand", "Million", "Billion", "Trillion"];
    private _and = "and";

    private numToText(num: number, lowerCase: boolean = true) {
        // Zero rule
        if (num === 0)
            return lowerCase ? this._small[0].toLowerCase() : this._small[0];

        // Extract the three-digit groups
        let groups: number[] = [];
        let positive = Math.abs(num);
        for (var i = 0; positive > 0 && i < this._scale.length; i++)
        {
            groups.push(positive % 1000);
            positive = Math.floor(positive / 1000);
        }

        // Convert each three-digit group to words
        let text: string[] = [];
        for (var group of groups)
            text.push(this.numGroupToText(group));

        // Recombine the three-digit groups
        let combined = text[0];
        let appendAnd = (groups[0] > 0) && (groups[0] < 100);
        for (var j = 1; j < groups.length; j++)
            if (groups[j] !== 0) {
                // Build the string to add as a prefix
                let prefix = `${text[j]} ${this._scale[j]}`;

                if (combined.length !== 0)
                    prefix += appendAnd ? ` ${this._and} ` : ", ";

                // Opportunity to add 'and' is ended
                appendAnd = false;

                // Add the three-digit group to the combined string
                combined = prefix + combined;
            }

        return lowerCase ? combined.toLowerCase() : combined;
    }

    // Converts a three-digit group into English words
    private numGroupToText(num: number): string {
        // Initialise the return text
        let text = "";

        // Determine the hundreds and the remainder
        const hundreds = Math.floor(num / 100);
        const tensUnits = num % 100;

        // Hundreds rules
        if (hundreds !== 0) {
            text += `${this._small[hundreds]} ${this._hundred}`;

            if (tensUnits !== 0)
                text += ` ${this._and} `;
        }

        // Determine the tens and units
        const tens = Math.floor(tensUnits / 10);
        const units = tensUnits % 10;

        // Tens rules
        if (tens >= 2) {
            text += this._tens[tens];
            if (units !== 0)
                text += ` ${this._small[units]}`;
        }
        else if (tensUnits !== 0)
            text += this._small[tensUnits];

        return text;
    }

    render() {
        return <>
            <div>
                <h1>TestOut License Terms</h1>
                <div>REVIEW THE LICENSE TERMS</div>
                <p>
                    LabSim products accessed via the TestOut website (www.testout.com) are
                    subject to the Single User License Agreement below.
                </p>
            </div>
            <div>
                <p>
                    This Single User License Agreement (the "Agreement") is a legal agreement
                    between you (either an individual or an entity) and TestOut Corporation
                    ("TestOut") for the LabSim client software, which includes computer software
                    and may include associated media and Internet-based services (the
                    "SOFTWARE") as well as user and technical manuals and any other materials
                    provided by Testout (whether in printed and electronic form) that describe
                    the installation, operation, use and technical specifications of the
                    SOFTWARE (the "Documentation").
                </p>
                <p className="bold">
                    BY CHECKING THE "I ACCEPT THE TESTOUT LICENSE TERMS" BOX AND
                    USING OR INSTALLING THE SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS
                    AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.
                    IF YOU DO NOT AGREE TO ANY OF THE TERMS BELOW, TESTOUT IS UNWILLING TO
                    LICENSE THE SOFTWARE TO YOU, AND YOU SHOULD CANCEL THE PURCHASE PROCESS.
                </p>
                <ol>
                    <li>
                        <span className="underline">Grant of License.</span> TestOut grants you
                        the rights set forth herein provided that you comply with all of the
                        terms and conditions of this Agreement. For the duration of the license
                        term from the day of activation, you may install, use, access,
                        display and run one copy of the SOFTWARE on up to seven (7) computers
                        such as a workstation, terminal or other device ("Computer") for use by
                        one single user. Your license to the SOFTWARE may not be shared with any
                        other person or used concurrently on different Computers. The SOFTWARE
                        is "in use" on a Computer when it is loaded into the temporary memory
                        (i.e., RAM) or installed into the permanent memory (e.g., hard disk,
                        CD-ROM, or other storage device) of a Computer. You may not distribute
                        or make the SOFTWARE available over a network or otherwise use it on
                        more than one Computer at the same time. Licenses for use on unlimited
                        Computers or multi-user licenses may also be purchased. Contact TestOut
                        for details regarding such unlimited and multi-user licenses. TestOut
                        reserves all rights not expressly granted to you in this Agreement.
                    </li>
                    <li>
                        <span className="underline">Additional Software and Services.</span> This
                        Agreement applies to updates, supplements, add-on components, or
                        Internet-based services components of the SOFTWARE that TestOut may
                        provide to you or make available to you after the date you obtain your
                        initial copy of the SOFTWARE, unless TestOut provides other terms along
                        with the update, supplement, add-on component, or Internet-based
                        services component. TestOut reserves the right to discontinue any
                        Internet-based services provided to you or made available to you through
                        the use of the SOFTWARE.
                    </li>
                    <li>
                        <span className="underline">Intellectual Property Rights; Ownership.</span>
                        <span className="bold">The SOFTWARE is licensed, not sold.</span> The
                        SOFTWARE is protected by United States copyright laws, international
                        copyright treaty provisions and other intellectual property laws and
                        treaties. TestOut or its suppliers own all right, title, and interest in
                        and to the SOFTWARE and all copyright and other intellectual property
                        rights arising out of or relating to the SOFTWARE except as expressly
                        granted to you in this Agreement. You shall promptly notify TestOut if
                        you become aware of any possible third-party infringement of TestOut's
                        intellectual property rights arising out of or relating to the SOFTWARE
                        and fully cooperate with TestOut in any legal action taken by TestOut
                        against third parties to enforce its intellectual property rights. You
                        shall safeguard the SOFTWARE (including all copies thereof) from
                        infringement, misappropriation, theft, misuse or unauthorized access.
                    </li>
                    <li>
                        <span className="underline">Use Restrictions.</span> You must not, and
                        shall not permit any third party to, in any manner:
                        <ol>
                            <li>
                                modify, alter, amend, fix, translate, enhance or otherwise
                                create derivative works of the SOFTWARE;
                            </li>
                            <li>
                                remove, disable, or otherwise create or implement any workaround
                                to, any security features contained in the SOFTWARE;
                            </li>
                            <li>
                                remove, delete or alter any trademarks, copyright notices or
                                other intellectual property rights notices of TestOut or its
                                licensors, if any, from the SOFTWARE;
                            </li>
                            <li>
                                copy the SOFTWARE, in whole or in part, except that you may
                                either (i) make one copy of the SOFTWARE or any part thereof
                                solely for backup or archival purposes, or (b) transfer the
                                SOFTWARE to a single hard disk provided you keep the original
                                solely for backup or archival purposes. Any copy of the SOFTWARE
                                or any part thereof made by you remains the property of TestOut,
                                is subject to the terms and conditions of this Agreement, and
                                must include all copyright and other intellectual property
                                notices contained in the original. If the SOFTWARE is an update
                                or has been updated, any such transfer must include the most
                                recent update and all prior versions. You may not copy the
                                Documentation;
                            </li>
                            <li>
                                assign, sublicense, sell, loan, convey, rent, lease, distribute,
                                publish, transfer or otherwise make the SOFTWARE available to
                                any third party for any reason;
                            </li>
                            <li>
                                reverse engineer, decompile, disassemble, decode or adapt the
                                SOFTWARE, or otherwise attempt to derive or gain access to the
                                source code of the SOFTWARE, in whole or in part (except as and
                                only to the extent this restriction is prohibited by applicable
                                law or to the extent as may be permitted by the licensing terms
                                governing use of any open-source components included with the
                                SOFTWARE);
                            </li>
                            <li>
                                use the SOFTWARE in violation of any federal, state or local
                                law, regulation or rule; or
                            </li>
                            <li>
                                use the SOFTWARE for purposes of competitive analysis, the
                                development of a competing software product or any other purpose
                                that is to TestOut's commercial disadvantage.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span className="underline">Term.</span> Thsis Agreement is effective
                        upon installation or use of the SOFTWARE. Unless terminated earlier as
                        provided hereunder, the license term shall be {this.numToText(this.props.termLength)} ({this.props.termLength}) months for
                        all purchases made on or before January 1, 2024, and twelve (12) months
                        for all purchases made on or after January 1, 2024.
                        You may terminate this Agreement by destroying all copies of the
                        SOFTWARE and any part thereof. TestOut may terminate this Agreement if
                        you fail to comply with any of the terms of this Agreement. Upon
                        expiration of the license or any other termination of
                        this Agreement, you must immediately discontinue use of the SOFTWARE and
                        promptly destroy all copies of the SOFTWARE.
                    </li>
                    <li>
                        <span className="underline">DISCLAIMER OF WARRANTIES.</span> YOU
                        EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SOFTWARE IS AT YOUR SOLE
                        RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, ACCURACY,
                        EFFORT AND PERFORMANCE OF THE SOFTWARE IS WITH YOU. THE SOFTWARE AND
                        DOCUMENTATION ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. TESTOUT
                        HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR
                        OTHERWISE, WITH RESPECT TO THE SOFTWARE, DOCUMENTATION, MEDIA AND ANY
                        OTHER SERVICES AND MATERIALS PROVIDED TO YOU UNDER THIS AGREEMENT,
                        INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                        QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND
                        WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE OR TRADE PRACTICE.
                        BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                        WARRANTIES, THE ABOVE EXCLUSION OF IMPLIED WARRANTIES MAY NOT APPLY TO
                        YOU.
                    </li>
                    <li>
                        <span className="underline">LIMITATION OF LIABILITY.</span> IN NO EVENT
                        SHALL TESTOUT OR ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, AGENTS,
                        CONTRACTORS, OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,
                        INCIDENTAL, PUNITIVE, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES
                        WHATSOEVER ARISING OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE OR
                        DOCUMENTATION, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
                        REVENUE OR PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, OR OTHER
                        PECUNIARY LOSS, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT
                        (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF TESTOUT HAS BEEN ADVISED OF
                        THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES WERE FORESEEABLE.
                        BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
                        STATES OR JURISDICTIONS, TESTOUT'S LIABILITY SHALL BE LIMITED TO THE
                        EXTENT PERMITTED BY LAW.<br />TESTOUT DOES NOT REPRESENT OR GUARANTEE
                        THAT THE SOFTWARE WILL BE FREE FROM LOSS, CORRUPTION, ATTACK, VIRUSES,
                        INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND TESTOUT HEREBY
                        DISCLAIMS ANY LIABILITY RELATING THERETO.
                    </li>
                    <li>
                        <span className="underline">No Maintenance/Support.</span> TestOut does
                        not and has no obligation under this Agreement to provide maintenance or
                        support for the Software.
                    </li>
                    <li>
                        <span className="underline">U.S. Government Restricted Rights.</span> The
                        SOFTWARE is deemed to be "commercial computer software" and "commercial
                        computer software documentation" as defined in FAR 12.212 and subject to
                        "restricted rights" as defined in FAR 52.227-19 "Commercial Computer
                        Licensed Software-Restricted Rights" and DFARS 227.7202. Any use,
                        modification, duplication, performance, display or disclosure of the
                        SOFTWARE by the U.S. Government shall be solely in accordance with the
                        terms of this Agreement. Manufacturer is TestOut Corporation, 50 South
                        Main St., Pleasant Grove, UT 84062.
                    </li>
                    <li>
                        <span className="underline">Export Regulation.</span> You acknowledge
                        that the Software, Documentation and related technical data and services
                        (collectively "Controlled Technology") are subject to the import and
                        export laws of the United States, specifically the U.S. Export
                        Administration Regulations (EAR), and the laws of any country where
                        Controlled Technology is imported or re-exported. You agree to comply
                        with all relevant laws and will not to export any Controlled Technology
                        in contravention to U.S. law nor to any prohibited country, entity, or
                        person for which an export license or other governmental approval is
                        required. You will indemnify and hold TestOut harmless from any and all
                        claims, losses, liabilities, damages, fines, penalties, costs and
                        expenses (including reasonable attorneys' fees) arising from or relating
                        to any breach by you of your obligations under this paragraph. Your
                        obligations under this paragraph will survive the termination of this
                        Agreement.
                    </li>
                    <li>
                        <span className="underline">Severability.</span> In the event that any
                        term or provision of this Agreement is declared or found to be illegal,
                        invalid or unenforceable, such provision shall be interpreted, construed
                        and reformed to the extent reasonably required to render it valid,
                        enforceable and consistent with its original intent and all other terms
                        and provisions of this Agreement shall remain in full force and effect.
                    </li>
                    <li>
                        <span className="underline">General.</span> This Agreement will be
                        governed by the laws of the State of Utah, without regard to or
                        application of conflicts of law rules or principles. Any legal suit,
                        action or proceeding arising out of or related to this Agreement or the
                        matters contemplated hereunder shall be instituted exclusively in the
                        federal courts of the United States or the courts of the State of Utah
                        in each case located in Salt Lake City, Utah, and the parties hereby
                        submit to the personal jurisdiction of such courts. In the event any
                        party institutes any legal suit, action or proceeding against the other
                        party arising out of related to this Agreement, the prevailing party in
                        such suit, action or proceeding shall be entitled to receive the costs
                        incurred by such party in conducting the suit, action or proceeding,
                        including reasonable attorneys' fees and expenses and court costs, in
                        addition to all other damages to which it may be entitled. The failure
                        of either party to require performance by the other party of any
                        provision hereof shall not affect the full right to require such
                        performance at any time thereafter; nor shall the waiver by either party
                        of a breach of any provision hereof be taken or held to be a waiver of
                        the provision itself. Neither this Agreement nor any of your rights or
                        obligations hereunder may be assigned or delegated by you in whole or in
                        part without the prior written approval of TestOut. Any purported
                        assignment or delegation in violation of the foregoing shall be null and
                        void. This Agreement is the complete and exclusive statement of
                        agreement between you and TestOut concerning the subject matter hereof
                        and supersedes all proposals and prior or contemporaneous agreements,
                        verbal or written, and any other communications between you and TestOut
                        relating the subject matter hereof. No amendment to this Agreement shall
                        be effective unless signed by a duly authorized representative of both
                        parties.
                    </li>
                </ol>
                <p>
                    Should you have any questions concerning this Agreement, or if you desire to
                    contact TestOut for any reason, please write: TestOut, 50 South Main St.,
                    Pleasant Grove, UT 84062.
                </p>
            </div>
        </>;
    }
}
