import * as React from 'react';
import './Header.scss';

interface IHeaderProps {

}

interface IHeaderState {

}

export default class Header extends React.PureComponent<IHeaderProps, IHeaderState> {
    constructor(props: IHeaderProps) {
        super(props);
    }

    render() {
        return <header className="App-header">
            <div>
                <a href="https://www.testout.com" title="TestOut" className="chromeless"><img width={136} height={29} src="Images/logo_testout.png" /></a>
            </div>
        </header>;
    }
}