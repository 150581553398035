import React from 'react';
import './App.scss';
import Header from './Header';
import PriceCodePage from './PriceCodePage';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Footer from "./Footer";
import {SessionContext, attachNotifySessionChanged, ISession, currentSession} from './SessionContext';
import ProductsPage from "./ProductsPage";
import CartPage from "./CartPage";
import CheckoutPage from "./CheckoutPage";
import PaymentPage from "./PaymentPage";
import ReceiptPage from "./ReceiptPage";
import TermsPage from "./TermsPage";

interface IAppProps {

}

interface IAppState {
    session?: ISession;
}

export default class App extends React.PureComponent<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);
        this.state = {session: currentSession};
    }

    componentDidMount() {
        attachNotifySessionChanged((session) => {
            this.setState({ session: session });
        });
    }

    render() {
        let allowProductsPage = this.state.session?.priceGroupCode != null &&
            (!this.state.session?.priceGroupCode.priceList?.isUserRequired || this.state.session?.cart?.user != null);

        if (window.location !== window.parent.location)
            return <div className="iframed">
                <p>The TestOut Shopping Cart must be loaded in an independant browser tab.</p>
                <a href="https://shop.testout.com" target="_blank">Launch TestOut Cart</a>
            </div>;

        return (
            <div className="App">
                <SessionContext.Provider value={this.state.session}>
                    <Header/>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={allowProductsPage ? ProductsPage : PriceCodePage} />
                            <Route exact path="/Products.aspx" component={allowProductsPage ? ProductsPage : PriceCodePage} />  { /* For compatibility with legacy Certification/LabSim sites */}
                            <Route exact path="/Cart" component={CartPage}/>
                            <Route exact path="/Checkout" component={CheckoutPage}/>
                            <Route exact path="/Payment" component={PaymentPage}/>
                            <Route exact path="/Payment.aspx" component={PaymentPage} /> { /* For compatibility with legacy cart */ }
                            <Route exact path="/Receipt" component={ReceiptPage}/>
                            <Route exact path="/Terms" component={TermsPage} />
                            <Route exact path="/Terms.aspx" component={TermsPage} />
                        </Switch>
                    </BrowserRouter>
                </SessionContext.Provider>
                <Footer/>
            </div>
        );
    }
}