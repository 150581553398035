import * as React from 'react';
import { KeyboardEvent } from 'react';
import {SessionContext, clearSession, setCart} from "./SessionContext";
import "./CartPage.scss";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import { CartItem } from '@testout/testout-commerce/models/commerce/CartItem';
import {Cart} from "@testout/testout-commerce/models/commerce/Cart";
import ico_close from "./Images/ico_close.png";
import Strings from '@testout/testout-commerce/core/Strings';
import MaintenanceMessage from './MaintenanceMessage';

interface ICartPageProps extends RouteComponentProps {

}

interface ICartPageState {

}

class CartPage extends React.PureComponent<ICartPageProps, ICartPageState> {
    constructor(props: ICartPageProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setQuantity(cart: Cart, cartitem: CartItem, quantity: number): void {
        if (isNaN(quantity))
            cartitem.quantity = 1;
        else if (quantity === 0) {
            cart.removeItem(cartitem)
            if (cart.items.length === 0)
                this.props.history.replace("/");
        }
        else cartitem.quantity = quantity;
        if (quantity === 10)
            cartitem.manualEdit = true;
        setCart(cart);
    }

    handleInputKeyDown(e: KeyboardEvent<HTMLInputElement>, cart: Cart, cartitem: CartItem) {
        if (!e.code.match(/\d/) && e.code !== "Delete" && e.code !== "Backspace" && e.code !== "ArrowLeft" && e.code !== "ArrowRight")
            e.preventDefault();
        else
            this.setQuantity(cart, cartitem, parseInt((e.target as HTMLInputElement).value))
    }

    render() {
        return <SessionContext.Consumer>
            {session => {
                if (session == null || session.cart == null || session.cart.items.length < 1) {
                    //You shouldn't be here, so we'll try to get you back where you belong
                    setTimeout(() => {this.props.history.replace("/")}, 1);
                    return <></>;
                }

                return <div className="App-body">
                    <MaintenanceMessage />
                    <div className="CartPage">
                        <h1>Shopping Cart</h1>
                        <p>Review the items in your cart below. LabSim courses and exams are delivered online. You will
                            receive an activation code by email that will allow you to access the content from your
                            LabSim account.</p>
                        <div className="CartPage-table">
                            <div className="CartPage-tableHeader">
                                <span>Cart Item</span>
                                <span>Price</span>
                                <div>Quantity</div>
                                <span>Total</span>
                            </div>
                            {session?.cart?.items.map((cartitem) => {
                                return <div className="CartPage-tableRow" key={cartitem.productNumber.toString()}>
                                    <div className="CartPage-productTitle">
                                        <img src={cartitem.thumbnailUrl} width={48} height={48}/>
                                        <div>
                                            {cartitem.title}{cartitem?.pricing?.product?.hasUserProperty && <span>&nbsp;{`(${session?.cart?.user?.contact.firstNames} ${session?.cart?.user?.contact.lastName})`}</span>}
                                            <div>
                                                <span>{cartitem.unitCode === "Each" ? "" : cartitem.unitCode} {cartitem?.pricing.product?.unitType}</span>{!Strings.isNullOrEmpty(cartitem.pricing?.product?.isbn) && <span>ISBN: {cartitem.pricing?.product?.isbn}</span>}
                                            </div>
                                        </div>
                                        <div className="CartPage-smallScreenPrice">{cartitem.unitPrice.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: cartitem.currencyCode
                                        })}</div>
                                    </div>
                                    <span className="CartPage-largeScreenPrice">
                                        {cartitem.unitPrice.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: cartitem.currencyCode
                                        })}
                                    </span>
                                    <div>
                                        {!cartitem.manualEdit &&
                                            <select value={cartitem.quantity}
                                                onChange={(e) => this.setQuantity(session?.cart as Cart, cartitem, parseInt(e.target.value))}>
                                                <option value="0">Remove</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10+</option>
                                            </select>}
                                        {cartitem.manualEdit &&
                                            <span>
                                            <input defaultValue={cartitem.quantity} type="text"  autoCorrect="off" spellCheck="false" maxLength={3}
                                                   onKeyDown={(e) => this.handleInputKeyDown(e, session?.cart as Cart, cartitem)}
                                                   onChange={(e) => this.setQuantity(session?.cart as Cart, cartitem, parseInt(e.target.value))} />
                                                <img className="CartPage-cancelInput" src={ico_close} width="16px" height="16px" onClick={() => this.setQuantity(session?.cart as Cart, cartitem, 0)} />
                                            </span>}
                                    </div>
                                    <span className="CartPage-itemTotal">
                                        {cartitem.discountedBaseAmount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: cartitem.currencyCode
                                        })}
                                   </span>
                                </div>
                            })}
                        </div>
                        <div className="CartPage-total">
                            <span>{session.cart.totalItemAmount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD' //session.cart.currencyCode
                            })}</span>
                            <span>Total</span>
                        </div>
                        <div className="CartPage-links">
                            <a onClick={() => this.props.history.push("/Checkout")}>Checkout</a>
                            <div>
                                <a className="underlinedlink" onClick={() => {
                                    clearSession();
                                    this.props.history.replace("/");
                                }}>Start Over</a>
                                <span>|</span>
                                <a className="underlinedlink" onClick={() => this.props.history.push("/")}>Continue Shopping</a>
                            </div>
                        </div>
                    </div>
                </div>}
            }
        </SessionContext.Consumer>;
    }
}

export default withRouter(CartPage);