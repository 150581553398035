import * as React from 'react';
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {clearSession, currentSession, ISession, SessionContext, setCart} from "./SessionContext";
import moment from "moment";
import "./ReceiptPage.scss";
import {Cart, CartStatus} from "@testout/testout-commerce/models/commerce/Cart";
import Strings from "@testout/testout-commerce/core/Strings";

interface IReceiptPageProps extends RouteComponentProps {

}

interface IReceiptPageState {
    fetching: boolean;
    activations?: IActivation[];
}

interface IActivation {
    code: string;
    title: string;
}

class ReceiptPage extends React.PureComponent<IReceiptPageProps, IReceiptPageState> {
    constructor(props: IReceiptPageProps) {
        super(props);
        this.state = {fetching: false};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.doFetch();
    }

    doFetch() {
        let cart = currentSession.cart;
        if (cart?.fulfillmentProvider.containsKey("LabSimServer") && !this.state.fetching) {
            this.setState({fetching: true}, async () => {
                try {
                    let cart = currentSession.cart as Cart;
                    let response = await fetch(`/controller/cart/activationcodes/${cart.orderNumber}?nonce=${cart.cartId}`);
                    if (response.status === 200) {
                        let activations = await response.json() as IActivation[];
                        this.setState({fetching: false, activations: activations});
                    } else this.setState({fetching: false})
                }
                catch {
                    this.setState({fetching: false})
                }
            });
        }
    }

    doCopy(code: string) {
        let textArea = document.createElement("textarea");
        textArea.value = code;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
    }

    render() {
        return <SessionContext.Consumer>
            {
                session => {
                    if (session == null || session.cart == null || session.cart.status == CartStatus.Open) {
                        //You shouldn't be here, so we'll try to get you back where you belong
                        setTimeout(() => {
                            this.props.history.replace("/")
                        }, 1);
                        return <></>;
                    }

                    let cart = session.cart;
                    let bCust = cart.billingCustomer ?? cart.shippingCustomer;
                    let sCust = cart.shippingCustomer;

                    return <div className="App-body">
                        <div className="CheckoutPage ReceiptPage">
                            <h1>Checkout</h1>
                            <ol className="CheckoutPage-progress">
                                <li className="complete"><span>Information</span></li>
                                <li className="complete"><span>Payment</span></li>
                                <li className="CheckoutPage-progress-current"><span>Receipt</span></li>
                            </ol>
                            <div className="CheckoutPage-columns">
                                <div>
                                    <div>
                                        <h3>Your order is complete.</h3>
                                        {cart?.fulfillmentProvider.containsKey("LabSimServer") && <>
                                            <div className="CheckoutPage-tip">
                                                <p className="tick">We will also send your activation codes by email. While most emails are sent within a few minutes of submitting your order, some may take longer. Please add custservice@testout.com to your safe-senders list or check your junk email folder to ensure you receive the email.</p>
                                            </div>
                                            <div className="ReceiptPage-Codes">
                                                {this.state.fetching && <>
                                                    <p>Give us just a moment to get your activation codes...</p>
                                                    <div><div className="spinner"></div></div>
                                                </>}
                                                {!this.state.fetching && this.state.activations != null && <>
                                                    <p>Please view our <a target="_blank" href="http://www.testout.com/instructions" className="chromeless bold">Getting Started</a> tutorial for instructions to access your LabSim course using the activation code below.</p>
                                                    <div>
                                                        {this.state.activations?.map((act) => {
                                                            return <div className="ReceiptPage-Activation" key={act.code}>
                                                                <span><span>{act.code}</span> <a className="reverse" onClick={() => this.doCopy(act.code)}>Copy</a></span>
                                                                <span>{Strings.isNullOrEmpty(act.title) ? "TestOut LabSim Course" : act.title}</span>
                                                            </div>
                                                        })}
                                                    </div>
                                                </>}
                                                {!this.state.fetching && this.state.activations == null && <>
                                                    <p>Your activation codes aren't available yet. Please check again or wait for them to be sent by email.</p>
                                                    <div><a onClick={() => this.doFetch()}>Check Again</a></div>
                                                </>}
                                            </div>
                                        </>}
                                        <div className="CheckoutPage-tip">
                                            <p className="tick">You may save or print the confirmation for your records. We'll also send a receipt by email. Please reference it when making inquiries regarding your order. Thank you.</p>
                                        </div>
                                        <div className="ReceiptPage-Receipt">
                                            <div>
                                                <img width={96} height={20} src="Images/logo_testout.png" />
                                                <p>50 South Main Street<br/>Pleasant Grove, UT 84062<br/>801-785-7900 office</p>
                                            </div>
                                            <div>
                                                <span>Date: <span>{moment(cart.orderDate).format("MM/D/YYYY")}</span></span>
                                                <span>Terms: <span>{cart.paymentTerms}</span></span>
                                            </div>
                                            <div>
                                                <span>Order Confirmation</span>
                                                <span>{cart.orderNumber}</span>
                                            </div>
                                            <div>
                                                <div>
                                                    <span>Billed To:</span>
                                                    <span>{bCust.name}</span>
                                                    <span>{bCust.address.postal.toString().split("\r\n").map(s => {return <span>{s}</span>})}</span>
                                                    <span>{bCust.address.phone}</span>
                                                    <span>{bCust.address.email}</span>
                                                </div>
                                                <div>
                                                    <span>Shipped To:</span>
                                                    <span>{sCust.name}</span>
                                                    <span>{sCust.address.postal.toString().split("\r\n").map(s => {return <span>{s}</span>})}</span>
                                                    <span>{sCust.address.phone}</span>
                                                    <span>{sCust.address.email}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div><span>Delivery: </span><span>{cart.fulfillmentProvider.map(kv => {return <span>{kv.Value}</span>})}</span></div>
                                                <p>{cart.fulfillmentComment}</p>
                                            </div>
                                            <div className="ReceiptPage-ReceiptTable">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th className="stretch left">Item</th>
                                                        <th className="center">Qty</th>
                                                        <th>Price</th>
                                                        <th>Total</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {cart.items.map((cartItem) => {
                                                        return <tr key={cartItem.productNumber.toString()}>
                                                            <td className="stretch left">{cartItem.title}</td>
                                                            <td className="center">{cartItem.quantity}</td>
                                                            <td>{cartItem.unitPrice.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: cartItem.currencyCode
                                                            })}</td>
                                                            <td><span className="discount"></span>{cartItem.discountedBaseAmount.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: cartItem.currencyCode
                                                            })}</td>
                                                        </tr>
                                                    })}
                                                    <tr>
                                                        <td rowSpan={7} className="summary"></td>
                                                        <td colSpan={2} className="summary">Subtotal</td>
                                                        <td className="summary">{cart.totalItemAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: cart.currencyCode
                                                        })}</td>
                                                    </tr>
                                                    {cart.totalItemDiscountAmount > 0 && <tr>
                                                        <td colSpan={2} className="summary-middle">- Discount</td>
                                                        <td className="summary-middle">{cart.totalItemDiscountAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: cart.currencyCode
                                                        })}</td>
                                                    </tr>}
                                                    {cart.totalTaxAmount > 0 && <tr>
                                                        <td colSpan={2} className="summary-middle">Sales Tax</td>
                                                        <td className="summary-middle">{cart.totalTaxAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: cart.currencyCode
                                                        })}</td>
                                                    </tr>}
                                                    {cart.freightAmount > 0 && <tr>
                                                        <td colSpan={2} className="summary-middle">Freight</td>
                                                        <td className="summary-middle">{cart.freightAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: cart.currencyCode
                                                        })}</td>
                                                    </tr>}
                                                    <tr>
                                                        <td colSpan={2} className="summary bold">Total</td>
                                                        <td className="summary bold">{cart.totalAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: cart.currencyCode
                                                        })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="summary-middle">- Payments</td>
                                                        <td className="summary-middle">{cart.totalPaymentAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: cart.currencyCode
                                                        })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="summary-middle">Due</td>
                                                        <td className="summary-middle">{cart.balanceDueAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: cart.currencyCode
                                                        })}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div>Thank You</div>
                                        </div>
                                    </div>
                                    <div className="CheckoutPage-links">
                                        <a onClick={() => { clearSession(); this.props.history.push("/") }}>Continue Shopping</a>
                                    </div>
                                </div>
                                <div>
                                    {/*This Empty div does need to be here to create the second column*/}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
        </SessionContext.Consumer>;
    }}

export default withRouter(ReceiptPage);