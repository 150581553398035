import * as React from 'react';
import moment from "moment";

import './Footer.scss'

interface IFooterProps {

}

interface IFooterState {

}

export default class Footer extends React.PureComponent<IFooterProps, IFooterState> {
    constructor(props: IFooterProps) {
        super(props);
    }

    render() {
        return <div className="App-footer">
            <div>
                <div>
                    <span>Copyright ©{moment().year()} TestOut Corporation®. Copyright © CompTIA, Inc. All rights reserved. | <a target="_blank" href="https://www.testout.com/company/privacy" className="chromeless">Privacy Policy</a></span>
                    <p>Microsoft, MCA, Office, Excel, Word and Windows are the trademarks of Microsoft. Cisco and CCNA are the trademarks of Cisco. Certified Ethical Hacker and CEH are the trademarks of the EC-Council. TestOut nor CompTIA have any affiliation with any of these companies and the products and services advertised herein are not endorsed by any of them.</p>
                </div>
                <div>
                    <iframe src="seal.html" frameBorder="0" width="115" height="65" scrolling="no" />
                    <div>TestOut Corporation® is PCI DSS Compliant.</div>
                </div>
            </div>
        </div>;
    }
}