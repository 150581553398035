import * as React from 'react';
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {currentSession, setCart} from "./SessionContext";
import { Cart, CartStatus, ICart } from "@testout/testout-commerce/models/commerce/Cart";
import Strings from '@testout/testout-commerce/core/Strings';

interface IPaymentPageProps extends RouteComponentProps {

}

interface IPaymentPageState {
    error?: string;
}

class PaymentPage extends React.PureComponent<IPaymentPageProps, IPaymentPageState> {
    constructor(props: IPaymentPageProps) {
        super(props);
        this.state = {};
    }

    static resumeRequests = {} as {[orderNum: string]: boolean};
    async componentDidMount() {
        window.scrollTo(0, 0);
        if (currentSession == null || currentSession.cart == null)
            this.redirect("/");
        else {
            let cart = currentSession.cart as Cart;
            if (cart.status === CartStatus.Open && !PaymentPage.resumeRequests[cart.orderNumber]) {
                PaymentPage.resumeRequests[cart.orderNumber] = true;
                const response = await fetch(`controller/cart/resumepayment${window.location.search}`, {
                    method: "POST",
                    body: JSON.stringify(cart),
                    headers: {'Content-Type': 'application/json'}
                });
                if (response.status === 200) {
                    cart = new Cart(await response.json() as ICart);
                    setCart(cart);
                    if (Strings.isNullOrEmpty(cart.paymentProviderErrorMessage))
                        this.redirect("/Receipt");
                    else {
                        if (Strings.isNullOrEmpty(cart.orderNumber))
                            this.setState({ error: "An error occurred in retrieving your payment from the payment provider, but you may have been charged for order. If you haven't received confirmation of your order, please call TestOut to verify your order status at 1-800-877-4889." });
                        else this.redirect("/Checkout");
                    }
                }
                else this.setState({ error: "An error occurred, you may refresh this page to retrieve a receipt for your purchase." });
            }
            else this.redirect("/Receipt");
        }
    }

    redirect(path: string) {
        setTimeout(() => {
            this.props.history.replace(path)
        }, 1);
    }

    render() {
        return <div className="App-body InitializingBodyPage">
            {this.state.error == null && <div><div className="spinner"></div></div>}
            {this.state.error != null && <div>{this.state.error}</div>}
        </div>;
    }
}

export default withRouter(PaymentPage);