import * as React from 'react';
import { Country, ICountry } from '@testout/testout-commerce/models/Country';
import {ReactElement} from "react";
import {Customer} from "@testout/testout-commerce/models/commerce/Customer";
import Strings from "@testout/testout-commerce/core/Strings";
import { Email } from "@testout/testout-commerce/models/Email";
import { v4 as uuidv4 } from 'uuid';

import ico_expand from "./Images/ico_expand.png";
import ico_collapse from "./Images/ico_collapse.png";

interface ICheckoutCustomerProps {
    customer: Customer;
    locationChange?: () => void;
    nameOrEmailChange?: () => void;
    autoCompleteSection: string;
}

interface ICheckoutCustomerState {
    customer?: Customer;
    country: ICountry;
    firstName: string;
    lastName: string;
    email: string;
    reEnterEmail: string;
    phone: string;
    company: string;
    line1: string;
    line2: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;

    validationMessage?: string;
    firstNameInvalid?: boolean;
    lastNameInvalid?: boolean;
    emailInvalid?: boolean;
    reEnterEmailInvalid?: boolean;
    phoneInvalid?: boolean;
    line1Invalid?: boolean;
    cityInvalid?: boolean;
    stateOrProvinceInvalid?: boolean;
    postalCodeInvalid?: boolean;

    threeLine: boolean;
}

export default class CheckoutCustomer extends React.PureComponent<ICheckoutCustomerProps, ICheckoutCustomerState> {
    constructor(props: ICheckoutCustomerProps) {
        super(props);
        this.state = this.buildStateFromProps(props);
    }

    buildStateFromProps(props: ICheckoutCustomerProps) {
        return {
            customer: props.customer,
            country: new Country(props.customer.address.postal.countryCode),
            firstName: props.customer.address.contactPerson.firstNames ?? "",
            lastName: props.customer.address.contactPerson.lastName ?? "",
            email: props.customer.address.email ?? "",
            reEnterEmail: props.customer.address.email ?? "",
            phone: props.customer.address.phone ?? "",
            company: props.customer.address.companyName ?? "",
            line1: props.customer.address.postal.line1 ?? "",
            line2: props.customer.address.postal.line2 ?? "",
            city: props.customer.address.postal.city ?? "",
            stateOrProvince: props.customer.address.postal.stateOrProvince ?? "",
            postalCode: props.customer.address.postal.postalCode ?? "",
            threeLine: !Strings.isNullOrEmpty(props.customer.address.companyName) || !Strings.isNullOrEmpty(props.customer.address.postal.line2)
        } as ICheckoutCustomerState;
    }

    componentWillReceiveProps(props: ICheckoutCustomerProps) {
        if (props.customer !== this.state.customer) {
            this.setState(this.buildStateFromProps(props), () => {
                this.doNameEmailChange();
                this.doLocationChange();
            });
        }
    }

    doLocationChange() {
        if (this.props.locationChange)
            this.props.locationChange();
    }

    doNameEmailChange() {
        if (this.props.nameOrEmailChange)
            this.props.nameOrEmailChange();
    }

    getUpdatedCustomer(updateInvalid: boolean) {
        let firstNameInvalid = Strings.isNullOrEmpty(this.state.firstName);
        let lastNameInvalid = Strings.isNullOrEmpty(this.state.lastName);
        let emailInvalid = Strings.isNullOrEmpty(this.state.email);
        let reEnterEmailInvalid = Strings.isNullOrEmpty(this.state.reEnterEmail);
        let phoneInvalid = Strings.isNullOrEmpty(this.state.phone);
        let streetAddressInvalid = Strings.isNullOrEmpty(this.state.line1);
        let cityInvalid = this.state.country.postalFormat.indexOf("0:") > -1 && Strings.isNullOrEmpty(this.state.city);
        let stateOrProvinceInvalid = this.state.country.postalFormat.indexOf("1:") > -1 && Strings.isNullOrEmpty(this.state.stateOrProvince);
        let postalCodeInvalid = this.state.country.postalFormat.indexOf("2:") > -1 && Strings.isNullOrEmpty(this.state.postalCode);

        let InvalidText = "";
        if (firstNameInvalid) InvalidText += "A first name is required. ";
        if (lastNameInvalid) InvalidText += "A last name is required. ";
        if (emailInvalid) InvalidText += "A E-mail address is required. ";
        if (!emailInvalid) {
            emailInvalid = !Email.isValid(this.state.email);
            if (emailInvalid) InvalidText += "The entered E-mail address is invalid. ";
            reEnterEmailInvalid = this.state.email !== this.state.reEnterEmail;
        }
        if (reEnterEmailInvalid) InvalidText += "Please confirm the E-mail is correct. ";
        if (phoneInvalid) InvalidText += "A telephone number is required. ";
        if (streetAddressInvalid) InvalidText += "A street address is required. ";
        if (cityInvalid) InvalidText += "A city is required. ";
        if (stateOrProvinceInvalid) InvalidText += "A state/province is required. ";
        if (postalCodeInvalid) InvalidText += "A Zip/Postal Code is required. ";

        let hasInvalid = firstNameInvalid || lastNameInvalid || emailInvalid || reEnterEmailInvalid || phoneInvalid ||
            streetAddressInvalid || cityInvalid || stateOrProvinceInvalid || postalCodeInvalid;

        if (updateInvalid) {
            this.setState({
                firstNameInvalid: firstNameInvalid,
                lastNameInvalid: lastNameInvalid,
                emailInvalid: emailInvalid,
                reEnterEmailInvalid: reEnterEmailInvalid,
                phoneInvalid: phoneInvalid,
                line1Invalid: streetAddressInvalid,
                cityInvalid: cityInvalid,
                stateOrProvinceInvalid: stateOrProvinceInvalid,
                postalCodeInvalid: postalCodeInvalid,
                validationMessage: InvalidText
            });
        }

        let c = new Customer(this.state.customer);
        c.address.postal.countryCode = this.state.country.countryCode;
        c.address.contactName = `${this.state.firstName} ${this.state.lastName}`.trim();
        c.address.email = this.state.email;
        c.address.phone = this.state.phone;
        c.address.companyName = this.state.threeLine ? (Strings.isNullOrEmpty(this.state.company) ? undefined : this.state.company) : undefined;
        c.address.postal.line1 = this.state.line1;
        c.address.postal.line2 = this.state.threeLine ? (Strings.isNullOrEmpty(this.state.line2) ? undefined : this.state.line2) : undefined;
        c.address.postal.city = this.state.city;
        c.address.postal.stateOrProvince = this.state.stateOrProvince;
        c.address.postal.postalCode = this.state.postalCode;
        return {customer: c, hasInvalid: hasInvalid};
    }

    getAddressEdits(): ReactElement<any, any>[] {
        let edits = [] as ReactElement<any, any>[];
        let matches = this.state.country.postalFormat.match(/{[^}]*}/g);
        if (matches) {
            matches.forEach(m => {
                let parts = m.replace('{', '').replace('}', '').split(":");
                let label = parts[1];
                if (parts[0] === "2") {
                    if (this.state.country.countryCode === "USA")
                        label = "Zip Code";
                    else label = "Postal Code";
                }

                if (parts[0] === "1" && this.state.country.provinces && this.state.country.provinces.length > 0) {
                    edits.push(
                        <>
                            <label className="CheckoutPage-generatedField">{label}</label>
                            <select className={this.state.stateOrProvinceInvalid ? "invalid" : ""} value={this.state.stateOrProvince} onChange={(e) => {
                                this.setState({ stateOrProvince: e.target.value }, () => {
                                    this.doLocationChange();
                                });
                            }} autoComplete={`${this.props.autoCompleteSection} region`}>
                                <option value="" selected={this.state.stateOrProvince === ""}></option>
                                {this.state.country.provinces?.map(p =>
                                    <option key={p.stateOrProvince} value={p.stateOrProvince}>{p.name}</option>
                                )}
                            </select>
                        </>
                    );
                }
                else {
                    let value = "";
                    let className = "";
                    let autoComplete = "";
                    switch (parts[0]) {
                        case "0": value = this.state.city; className = this.state.cityInvalid ? "invalid" : ""; autoComplete = "locality"; break;
                        case "1": value = this.state.stateOrProvince; className = this.state.stateOrProvinceInvalid ? "invalid" : ""; autoComplete = "region"; break;
                        case "2": value = this.state.postalCode; className = this.state.postalCodeInvalid ? "invalid" : ""; autoComplete = "postal-code"; break;
                    }
                    edits.push(
                        <>
                            <label className="CheckoutPage-generatedField">{label}</label>
                            <input type="text" className={className} value={value} autoComplete={`${this.props.autoCompleteSection} ${autoComplete}`} autoCorrect="off" spellCheck="false" maxLength={100} onChange={(e) => {
                                switch (parts[0]) {
                                    case "0": this.setState({city: e.target.value}); break;
                                    case "1": this.setState({stateOrProvince: e.target.value}); break;
                                    case "2": this.setState({postalCode: e.target.value}); break;
                                }
                            }}
                            onKeyDown = {(e) => {
                                if ((e.code === "Enter" || e.code === "NumpadEnter") && this.props.locationChange)
                                    this.props.locationChange();
                            }}
                            onBlur = {(e) => {
                                if (this.props.locationChange)
                                    this.props.locationChange();
                            }}
                            />
                        </>
                    );
                }
            });
        }

        if (this.state.country.countryCode === "USA" || this.state.country.countryCode === "CAN")
            edits = [edits[0], <div><div>{edits[1]}</div><div>{edits[2]}</div></div>];

        return edits;
    }

    render() {
        return <>
            {!Strings.isNullOrEmpty(this.state.validationMessage) && <div className="validationMessage">{this.state.validationMessage}</div>}
            <label>Country</label>
            <select value={this.state.country.countryCode} onChange={(e) => {
                    this.setState({country: new Country(e.target.value), stateOrProvince: ""}, () => {
                        if (this.props.locationChange)
                            this.props.locationChange();
                    });
            }} autoComplete={`${this.props.autoCompleteSection} country`}>
                {Country.byName.map(c => {
                    return <option key={c.countryCode} value={c.countryCode}>{c.name}</option>
                })}
            </select>
            <div>
                <div>
                    <label>First Name</label>
                    <input type="text" className={this.state.firstNameInvalid ? "invalid" : ""} value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} onBlur={() => this.doNameEmailChange()} autoComplete={`${this.props.autoCompleteSection} given-name`} autoCorrect="off" spellCheck="false" maxLength={50} />
                </div>
                <div>
                    <label>Last Name</label>
                    <input type="text" className={this.state.lastNameInvalid ? "invalid" : ""} value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} onBlur={() => this.doNameEmailChange()} autoComplete={`${this.props.autoCompleteSection} family-name`} autoCorrect="off" spellCheck="false" maxLength={50} />
                </div>
            </div>
            <label>E-mail</label>
            <input type="text" className={this.state.emailInvalid ? "invalid" : ""} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} onBlur={() => this.doNameEmailChange()} autoComplete={uuidv4()} onPaste={(e) => e.preventDefault()} autoCorrect="off" spellCheck="false" maxLength={100} />
            <label>Re-enter E-mail</label>
            <input type="text" className={((this.state.reEnterEmailInvalid ? "invalid" : "") + (this.state.email.length > 0 && this.state.email === this.state.reEnterEmail ? " inputOverlay match" : " inputOverlay")).trim()} value={this.state.reEnterEmail} onChange={(e) => this.setState({ reEnterEmail: e.target.value })} autoComplete={uuidv4()} onPaste={(e) => e.preventDefault()} autoCorrect="off" spellCheck="false" maxLength={100} />
            <label>Telephone</label>
            <input type="text" className={this.state.phoneInvalid ? "invalid" : ""} value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} autoComplete={`${this.props.autoCompleteSection} tel`} autoCorrect="off" spellCheck="false" maxLength={100} />
            {this.state.threeLine && <>
                <label>Company or c/o (Optional)</label>
                <input type="text" value={this.state.company} onChange={(e) => this.setState({ company: e.target.value })} autoCorrect="off" spellCheck="false" />
            </>}
            <label>Street Address (including any APT, STE, etc.)</label>
            <input type="text" className={this.state.line1Invalid ? "invalid inputOverlay" : "inputOverlay"} value={this.state.line1} onChange={(e) => this.setState({ line1: e.target.value })} onBlur={() => {
                if (this.props.locationChange)
                    this.props.locationChange();
            }} autoComplete={`${this.props.autoCompleteSection} address-line1`} autoCorrect="off" spellCheck="false" maxLength={255} />
            <img className="CheckoutPage-inputToggle" src={this.state.threeLine ? ico_collapse : ico_expand} onClick={() => { this.setState({ threeLine: !this.state.threeLine }) }} />
            {this.state.threeLine && <>
                <label>Address Line 2 (Optional)</label>
                <input type="text" value={this.state.line2} onChange={(e) => this.setState({ line2: e.target.value })} autoComplete={`${this.props.autoCompleteSection} address-line2`} autoCorrect="off" spellCheck="false" maxLength={255} />
            </>}
            {this.getAddressEdits()}
        </>
    }
}