import * as React from 'react';
import Strings from '@testout/testout-commerce/core/Strings';
import error_img from './Images/ico_error_large.png';
import './MaintenanceMessage.scss';

interface IMaintenanceMessageProps {

}

interface IMaintenanceMessageState {
    msg: string | undefined;
}

export default class MaintenanceMessage extends React.PureComponent<IMaintenanceMessageProps, IMaintenanceMessageState> {
    constructor(props: IMaintenanceMessageProps) {
        super(props);
        this.state = {msg: ''};
    }

    private static _msg: string | undefined = undefined;
    async componentDidMount() {
        if (MaintenanceMessage._msg == undefined) {
            const response = await fetch('maintenancemessage.txt');
            if (response.status == 200)
                MaintenanceMessage._msg = await response.text();
        }
        this.setState({ msg: MaintenanceMessage._msg});
    }

    render() {
        return <>
            {!Strings.isNullOrEmpty(this.state.msg) && <div className="MaintentanceMessage">
                <div>
                    <img src={error_img} width="40" height="40" />
                    <span>{this.state.msg}</span>
                </div>
            </div>}
        </>;
    }
}